import React, { useEffect, useState } from "react";
import { host, useConstructor } from "../../Utils";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";


export default function ProgramaAuditoriasDocumento(props) {
    const [numPages, setNumpages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [innerSize, setInnerSize] = useState(0);


    useEffect(() => {
        let widthSize = window.innerWidth;
        console.log(widthSize);
        setInnerSize(widthSize);
    }, [setInnerSize]);


    let prevPage = () => {

        if (pageNumber !== 2) { setPageNumber(pageNumber - 2) }
        else { setPageNumber(pageNumber - 1) }
    }

    let nextPage = () => {
        let val = 0;

        if ((pageNumber === 1) || (pageNumber + 1 > numPages))
            val = 1;
        else
            val = 2;

        setPageNumber(pageNumber + val);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumpages(numPages);
    }

    return (
        <div>
            <div className="flex-container" style={{ justifyContent: "center" }}>
                <div className="justify-content-center">
                    {innerSize >= 480 ? (
                        <>
                            <Document
                                renderMode="png"
                                className="p-3 border bg-light"
                                //file={host() + "/Publico/Documento/5022"} //local
                                file={host() + "/Publico/Documento/6489"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageIndex={1} fixed pageNumber={pageNumber}></Page>
                                {pageNumber !== 1 ? pageNumber !== numPages ? <Page fixed pageNumber={pageNumber + 1}></Page> : '' : ''}
                            </Document>
                            <div className="justify-content-center d-flex btn-pages">
                                <div style={{ marginTop: "10px" }}>
                                    <button style={pageNumber === 1 ? { display: "none" } : {}}
                                        onClick={prevPage}
                                        className="btn-outlined btn-sm btn-page"
                                    >
                                        <i className="bi bi-arrow-left"></i>
                                    </button>
                                    {pageNumber} / {numPages}{" "}
                                    <button style={pageNumber === numPages ? { display: "none" } : {}}
                                        onClick={nextPage}
                                        className="btn-outlined btn-sm btn-page"
                                    >
                                        <i className="bi bi-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Document
                                renderMode="png"
                                className="p-3 border bg-light"
                                file={host() + "/Publico/Documento/6115"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageIndex={1} fixed pageNumber={pageNumber}></Page>
                            </Document>
                            <div className="justify-content-center d-flex btn-pages">
                                <div style={{ marginTop: "10px" }}>
                                    <button style={pageNumber === 1 ? { display: "none" } : {}}
                                        onClick={() => setPageNumber(pageNumber - 1)}
                                        className="btn-outlined btn-sm btn-page"
                                    >
                                        <i className="bi bi-arrow-left"></i>
                                    </button>
                                    {pageNumber} / {numPages}{" "}
                                    <button style={pageNumber === numPages ? { display: "none" } : {}}
                                        onClick={() => setPageNumber(pageNumber + 1)}
                                        className="btn-outlined btn-sm btn-page"
                                    >
                                        <i className="bi bi-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="text-center"> <a href={host() + "/Publico/Documento/6489"}>Descargar Programa Anual de Auditorías 2024 - 2025</a></div>
        </div>
    )
}