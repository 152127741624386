import React, { useState } from 'react';
import ImgLegal from "../images/iconos/tipoAuditorias/Legales.png";
import ImgDenuncias from "../images/iconos/icono denuncias.png";
import ImgTransparencia from "../images/iconos/icono transparencia.png";
import ImgRevista from "../images/iconos/icono revista.png";
import ImgCuentaPublica from "../images/iconos/icono cuenta publica.png";
import ImgInstitucional from "../images/iconos/icono correo institucional.png";
import ImgParticipacion from '../images/orientacionCiudadana/logrosExperiencias/LOGOComiteParticipacionCiudadana.jpg'
import ImgConvocatoria from '../images/convocatoria/5taConvocatoria.png'
import ImgSigas from '../images/logo-sigas-200-stroke.png'
import ImgIntranet from '../images/InformeAct.png'
import ImgRevistaTarjeta from '../images/Valor Público Logo Color.png'
import ProgramaAuditorias from '../images/programaanual/paa2024.png'


export default function AccesosFrecuentes() {
    const cssIntegral = {
        cursor: 'pointer'
    }
       return (
            <div>
               <div className='tarjetas-home'>
               <TarjetaInteractiva logo={ImgConvocatoria} titulo="CONVOCATORIA" convocatoria="true" contenidoInicial="Contenido inicial de la tarjeta 1." textoAdicional="Anualmente el Instituto publica una convocatoria de participación ciudadana con el objetivo de promover la intervención de las y los sonorenses en la fiscalización. A través de ésta la población puede realizar solicitudes, propuestas o peticiones en temas que consideren de interés a fin de incorporarlos en el Programa Anual de Auditorías del ejercicio correspondiente." direccion="/transparencia/participacion-ciudadana" />
                   <TarjetaInteractiva logo={ProgramaAuditorias} titulo="CONSULTALO AQUÍ" contenidoInicial="Contenido inicial de la tarjeta 4." textoAdicional="Correspondiente al ejercicio 2024, en el cual se señalaban la totalidad de los sujetos de fiscalización
                    y los períodos de revisión, entre otros aspectos relevantes" direccion="/auditorias/programaauditoriadocumento" />
               <TarjetaInteractiva logo={ImgIntranet} titulo="INFORME DE ACTIVIDADES" convocatoria="true" contenidoInicial="Contenido inicial de la tarjeta 3." textoAdicional="Este Informe de Actividades se describen las acciones y resultados que el Instituto Superior de Auditoría y Fiscalización (ISAF), llevó a cabo durante un período establecido." direccion="/informes/informe-actividades" />
               <TarjetaInteractiva logo={ImgSigas}  titulo="SIGAS" contenidoInicial="Contenido inicial de la tarjeta 2." textoAdicional="El SIGAS es una plataforma para apoyar al proceso de seguimiento y solventación de observaciones que ayuda a controlar todo el proceso de auditoría que va desde la captura y programación del Programa Anual de Auditorias (PAA), hasta la generación automatizada de los informes individuales y de resultados." direccion="https://sigas.isaf.gob.mx/Account/Login?ReturnUrl=%2F" />
               </div>
               <h2 style={{ textAlign: "center", margin: 40, color: "#6a6a6a" }}>
                   <b className="font-title">ACCESOS FRECUENTES</b>
               </h2>
               
               <div className="tipoauditorias-container">
                   <div className="cuadro-tipo tipo-uno hide-scroll" style={cssIntegral} onClick={() => { window.open('/institucion/denuncias', '_self') }}>
                    <div className='tipo-In'>
                       <img alt='' src={ImgDenuncias} />
                       <span>Denuncias</span>
                    </div>
                       
                   </div>

                   <div className="cuadro-tipo tipo-dos hide-scroll" style={cssIntegral} onClick={() => { window.open('/transparencia/obligaciones-de-transparencia', '_self') }}>
                   <div className='tipo-In'>
                       <img alt='' src={ImgTransparencia} />
                       <span>Transparencia</span> 
                   </div>
                   </div>


                   <div className="cuadro-tipo tipo-tres hide-scroll" style={cssIntegral} onClick={() => { window.open('/difusion/revista-valor-publico ', '_self') }}>
                   <div className='tipo-In'>
                       <img alt='' src={ImgRevista} />
                       <span>Revista Valor Público</span>  
                    </div>

                   </div>

                  
                   <div className="cuadro-tipo tipo-uno hide-scroll" style={cssIntegral} onClick={() => { window.open('/informes/cuenta-publica', '_self') }}>
                   <div className='tipo-In'>
                       <img alt='' src={ImgCuentaPublica} />
                       <span>Cuenta Pública</span>
                    </div>
                       
                   </div>

                   <div className="cuadro-tipo tipo-dos hide-scroll" style={cssIntegral} onClick={() => { window.open('https://outlook.office.com/mail/', '_self') }}>
                   <div className='tipo-In'>
                       <img alt='' src={ImgInstitucional} />
                       <span>Correo Electrónico</span>
                    </div>
                       
                   </div>


               </div>

</div>
        );
    

}

const TarjetaInteractiva = ({ logo,titulo, convocatoria, textoAdicional, direccion}) => {
    const [expandido, setExpandido] = useState(false);
  
    const toggleExpandido = () => {
      setExpandido(!expandido);
    };
  
    return (
      <div className={`tarjeta-home ${expandido ? 'expandido' : ''}`}>
        <div  onClick={() => { window.open(`${direccion}`, '_self') }}>
        <div style={{textAlign:'center'}} >
                    <img alt='' className={convocatoria ? '' : 'p-2'}  src={logo}/>
                    <h2 className='font-title ps-3 pe-3'>{titulo}</h2>
            </div>
          {expandido && (
            <div className={`texto-adicional ${expandido ? 'visible' : ''}`} >
              <p>{textoAdicional}</p>
            </div>
          )}
        </div>
        <div className="flecha" onClick={toggleExpandido}>
          {expandido ? <i class="bi bi-arrow-up-circle-fill"></i> : <i class="bi bi-arrow-down-circle-fill"></i>}
        </div>
      </div>
    );
  };
  
