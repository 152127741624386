import React, { useEffect, useState } from 'react';
import { hostSigas, useConstructor } from '../../Utils';
import '../InformesIndividuales.css';
import axios from 'axios';

export const InformeCiudadanoActual = (props) => {
    const [, setEntes] = useState([]);
    const [tiposAuditoria, setTiposAuditoria] = useState([9]);
    const [timeoutId, setTimeoutId] = useState(0);
    const [enteSelected, setEnteSelected] = useState({});
    const [Informe, setInforme] = useState("");
    const [Success, setSuccess] = useState(0);

    const [numPages, setNumpages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [innerSize, setInnerSize] = useState(0);

    useConstructor(() => {
        props.setTitle("Informes Ciudadanos")
    });

    useEffect(() => {
        updateInforme(enteSelected);
        let widthSize = window.innerWidth;
        console.log(widthSize);
        setInnerSize(widthSize);
    }, [enteSelected, setInnerSize]);





    function onDocumentLoadSuccess({ numPages }) {
        setNumpages(numPages);
    }




    const updateInforme = (ente) => {

        if (ente.id !== undefined) {
            setSuccess(0);

            setInforme("")
            axios.get(hostSigas() + '/api/Informes/Ciudadano/' + ente.id + '/18/')
                .then(res => {
                    setSuccess(1)
                    console.log('infrome:  ', res.data)

                    axios.get(hostSigas() + '/api/File/DownloadPublic/' + res.data)
                        .then((x) => {
                            console.log(x.data.blockBlob.uri + x.data.sharedPolicy)
                            setInforme(x.data.blockBlob.uri + x.data.sharedPolicy)

                        }).catch(x => {
                            console.log(x)
                            setSuccess(2)
                        })


                    document.getElementById("main").scrollIntoView();
                }).catch(x => {
                    setInforme("")
                    setSuccess(2)
                    console.log(x)
                })

        }
    }




    const handleSelected = (newValue) => {
        setEnteSelected(newValue);



    }
    const handleDownloadFile = (id,nombredescarga) => {
        axios.get(hostSigas()+'/api/Informes/documentos/'+id).then((x)=>{
            const res=x.data
            //console.log(res)
            //console.log(res[0].informeEscaneado, nombredescarga)
            downloadFile(res[0].informeEscaneado, nombredescarga)
        })
       
    }
    
    
    const downloadFile=(nombre,nombredescarga)=>{
        var link = document.createElement('a');
        window.open(`${hostSigas()}/api/Storage/GetUrl/${nombre}/${nombredescarga}`);
        link.click();
    }


    const loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            setEntes([])
            callback([]);
            return;
        }
        clearTimeout(timeoutId);
        var timeout_id = setTimeout(() => {
            axios.get(hostSigas() + "/api/Entes/website/" + inputValue).then(res => {
                if (res.data.length > 0) {
                    var result = res.data.map(x => {
                        x.value = x.id;
                        x.label = (x.nombre !== x.nombreCorto ? x.nombreCortoCompleto : x.nombre);
                        return x;
                    });
                    setEntes(result)
                    callback(result);
                } else {
                    setEntes([])
                    callback([]);
                }
            }).catch(res => console.log(res));
        }, 1000);
        setTimeoutId(timeout_id)
    };



    return (

        <>
            <div className='mt-5'>
                <div className='d-flex' style={{justifyContent:'center'}}>
                <div class="tarjetaCiudadana mb-3">
                    <div class="contentTarjetaCiudadana">
                        <h3>Informe Ciudadano</h3>
                        <hr style={{borderColor:'#a8a8a7'}}/>
                        <p>Finanzas Públicas Estatales 2023.</p>
                        
                    </div>
                    <div class="hidden-button">
                    <button onClick={() => handleDownloadFile(22398,"Cidadano_Estatal_2023.pdf")}>
    <i style={{fontSize: '30px'}} className="bi bi-file-earmark-arrow-down"></i>
    <p style={{fontSize: 'small'}}>Descargar Informe</p>
</button>

                    </div>
                </div>
                </div>
                <div className='d-flex' style={{justifyContent:'center'}}>

                <div class="tarjetaCiudadana mb-3">
                    <div class="contentTarjetaCiudadana">
                        <h3>Informe Ciudadano</h3>
                        <hr style={{borderColor:'#a8a8a7'}}/>
                        <p>Finanzas públicas municipales 2023.</p>
                    </div>
                    <div class="hidden-button">
                            <button onClick={() => handleDownloadFile(22397, "Cidadano_Municipal_2023.pdf")}><i style={{fontSize:'30px'}} class="bi bi-file-earmark-arrow-down"></i><p style={{fontSize:'small'}}>Descargar Informe</p></button>
                    </div>
                </div>
                </div>
                <div className='d-flex' style={{justifyContent:'center'}}>

                <div class="tarjetaCiudadana">
                    <div class="contentTarjetaCiudadana">
                        <h3>Informe Ciudadano</h3>
                        <hr style={{borderColor:'#a8a8a7'}}/>
                        <p>Revisión a la obra pública estatal y municipal 2023.</p>
                    </div>
                    <div class="hidden-button">
                            <button onClick={() => handleDownloadFile(22399,"Cidadano_Obras_2023.pdf")}><i style={{fontSize:'30px'}}  class="bi bi-file-earmark-arrow-down"></i><p style={{fontSize:'small'}}>Descargar Informe</p></button>
                    </div>
                </div>
                </div>

            </div>
        </>
    );
}
