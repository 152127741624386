import React, { useState, useRef } from 'react'
import './ConvocatoriaAbierta.css'
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ImgConvocatoriaPg1 from './../../../images/convocatoria/5ta. Convocatoria de Participación Ciudadana_page-0001.jpg'
import ImgConvocatoriaPg2 from './../../../images/convocatoria/5ta. Convocatoria de Participación Ciudadana_page-0002.jpg'
import ImgConvocatoriaPg3 from './../../../images/convocatoria/Participa aqui.png'

import { LazyLoadImage } from "react-lazy-load-image-component";
import AsyncSelect from 'react-select/async';
import { host, hostSigas } from '../../../Utils';

export const ConvocatoriaAbierta = () => {
  const MySwal = withReactContent(Swal)
  const fileInput = useRef(null);
  const [otroSeleccionado, setOtroSeleccionado] = useState(false);
  const [sectorLaboral, setSectorLaboral] = useState('')
  const [, setEntes] = useState([]);
  const [timeoutId, setTimeoutId] = useState(0);
  const [enteSelected, setEnteSelected] = useState({});
  const [formDenuncia, setValuesDenuncia] = useState({
    Denunciante: '',
    Organizacion: '',
    Ambito: 'Estatal',
    AreaDeInteres: '',
    Direccion: '',
    Email: '',
    Telefono: '',
    Dependencia: '',
    Motivo: '',
    Tipo: '',
    EntidadPublica:'',
    Evidencia: '',
    ParteDelComite: false,
    TelefonoDeContacto: "",
    Edad: null,
    SectorLaboral: '',
    Sexo: '',
    LugarDeNacimiento: '',
    LugarDeReincidencia: '',
    ArchivoEvidencia: fileInput,
  })

  function CleanUp() {
    setValuesDenuncia({
      Denunciante: '',
      Organizacion: '',
      Ambito: 'Estatal',
      AreaDeInteres: '',
      Direccion: '',
      Email: '',
      Telefono: '',
      Dependencia: '',
      Motivo: '',
      Tipo: '',
      EntidadPublica:'',
      Evidencia: '',
      ParteDelComite: false,
      TelefonoDeContacto: "",
      Edad: null,
      SectorLaboral: '',
      Sexo: '',
      LugarDeNacimiento: '',
      LugarDeReincidencia: '',
      ArchivoEvidencia: fileInput,
    })
    setOtroSeleccionado(null)
    setSectorLaboral('')
  }

  const handleSexoChange = (e) => {
    console.log(e.target.value)
    setValuesDenuncia({
      ...formDenuncia,
      Sexo: e.target.value
    });
  };
  const handleAmbitoChange = (e) => {
    console.log(e)
    setValuesDenuncia({
      ...formDenuncia,
      Ambito: e.target.value
    });
  };
  const handleSectorLaboralChange = (e) => {
    let isChecked = false
    if (e.target.value === 'Otro') {
      isChecked = true
    }
    setOtroSeleccionado(isChecked);
    setSectorLaboral("")
    setValuesDenuncia({
      ...formDenuncia,
      SectorLaboral: e.target.value
    });

  };

  
  const updateField = e => {
    const { name, value } = e.target;
    if (name === 'Edad' && (value <= 0 || value === '')) {
      setValuesDenuncia({
        ...formDenuncia,
        Edad: ''
      });
    } else {
      setValuesDenuncia({
        ...formDenuncia,
        [name]: value
      });
    }
  };

  const postDenuncia = e => {
    e.preventDefault();

console.log(formDenuncia.Edad)
    if (verifyRequired(formDenuncia)) {

      document.getElementById("SubmitButton").disabled = true;

      var formData = new FormData();
      var attatchment = document.querySelector('#EvidenciaPC');
      formData.append("file", attatchment.files[0]);
      console.log(formDenuncia)
      const denuncia = {
        Denunciante: formDenuncia.Denunciante,
        Ambito: formDenuncia.Ambito,
        ParteDelComite: formDenuncia.ParteDelComite ? "si" : "no",
        Organizacion: formDenuncia.Organizacion,
        AreaDeInteres: formDenuncia.AreaDeInteres,
        SectorLaboral:formDenuncia.SectorLaboral==='Otro'? sectorLaboral:formDenuncia.SectorLaboral,
        Direccion: formDenuncia.Direccion,
        Email: formDenuncia.Email,
        Telefono: formDenuncia.Telefono,
        EntidadPublica:enteSelected.nombre,
        Edad: formDenuncia.Edad != null ? formDenuncia.Edad : 0,
        Dependencia: "No Aplica",
        Motivo: formDenuncia.Motivo,
        ArchivoEvidencia: attatchment.files[0],
        Tipo: 'Participación Ciudadana',
        Evidencia: formDenuncia.Evidencia,
        Sexo: formDenuncia.Sexo,
        LugarResidencia:formDenuncia.LugarDeReincidencia
      }
      console.log(denuncia)
      const json = JSON.stringify(denuncia);

      const data = new FormData();
      data.append("denuncia", json);

      data.append("file", attatchment.files[0])

      console.log('data', data)
      Swal.fire({
        title: 'Enviando..',
        text: 'Por favor espere',
        showConfirmButton: false

      })
      axios({
        method: 'post',
        url: getSource() + '/Publico/Denuncia',
        data: data,
      }).then(res => {

        MySwal.fire(<p>{res.data.Tipo} registrada con éxito, El número de Folio de su {res.data.Tipo} es: {res.data.Folio}.</p>)
        CleanUp()

        document.getElementById("SubmitButton").disabled = false;
      })
    } else {
      MySwal.fire(<p>Por favor, llene los campos <i>requeridos.</i></p>)
    }
  };

  const getSource = () => {
    var source = '';
    if (document.location.href.includes('localhost')) {
      source = 'https://localhost:44365'
    } else {
      source = 'https://intranet.isaf.gob.mx'
    }
    return source;
  }



  const verifyRequired = e => {
    if (
      e.Denunciante !== "" &&
      e.Email !== "" &&
      e.Motivo !== "" &&
      e.AreaDeInteres !== "") {
      return true;
    }
    else {
      return false;
    }

  }
  function fileNames() {
    const { current } = fileInput;

    if (current && current.files.length > 0) {
      let messages = [];
      for (let file of current.files) {
        messages = messages.concat(<p key={file.name}>{file.name}</p>);
      }
      return messages;
    }
    return null;
  }

  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
        setEntes([])
        callback([]);
        return;
    }
    clearTimeout(timeoutId);
    var timeout_id = setTimeout(() => {
      axios.get(hostSigas() + "/api/Entes/websiteActivos/" + inputValue).then(res => {
        if (res.data.length > 0) {
            var result = res.data.map(x => {
                x.value = x.id;
                x.label = x.nombreCompleto;
                return x;
            });
            console.log('entre al if ', result)
            setEntes(result)
            callback(result);
        } else {
          console.log('entre al else')
            setEntes([])
            callback([]);
        }
    }).catch(res =>console.log(res));
    }, 1000);
    setTimeoutId(timeout_id)
};

const handleSelected = (newValue)=>{
    setEnteSelected(newValue); 
    formDenuncia.EntidadPublica=newValue.nombre
    console.log(newValue)
    
}



  return (
    <>
      {/* <div className='fondo3'>
    <div className="text-container" >
        <h1>CONVOCATORIA ABIERTA</h1>
    </div>
    </div> */}
          <div style={{ marginTop: '' }}>
             
        {/* <p>El Instituto Superior de Auditoría y Fiscalización (ISAF), es un organismo público autónomo, encargado de revisar y fiscalizar los estados financieros, cuentas públicas estatales y municipales, los ingresos y egresos, así como el manejo, custodia y aplicación de los recursos públicos.<br />
        Por tanto, te invitamos a aportar tus ideas, propuestas y/o solicitudes, a través de la <strong>4ta. Convocatoria de Participación Ciudadana: En la fiscalización 2023.</strong> Si ésta es seleccionada será incorporada al Programa Anual de Auditorías, Visitas e Inspecciones 2023-2024 (PAA 23-24).</p> */}
         <div className='d-flex' style={{justifyContent:'end'}}>
      <a className='p-2 mb-3' style={{borderRadius:'10px', color:'white',backgroundColor:'#832347'}} href={host() + "/Publico/Documento/6406"}>Descargar 5ta. Convocatoria de Participación Ciudadana</a>
    </div>
        <LazyLoadImage src={ImgConvocatoriaPg1} style={{ maxWidth: '100%' }} alt="Image Alt" />
        <LazyLoadImage src={ImgConvocatoriaPg2} style={{ maxWidth: '100%' }} alt="Image Alt" />
        <div style={{backgroundColor:'white'}}>
        <LazyLoadImage src={ImgConvocatoriaPg3} style={{ maxWidth: '60%' }} alt="Image Alt" />
              </div>
              
        <Form id="Form-Denuncia" onSubmit={postDenuncia}>
          <FormGroup>
            <div className='p-2' style={{ backgroundColor: 'white' }} >

              <div className='d-flex flex-wrap'>
                <div className='col-sm-6 col-12 p-2'>
                  <Label>Nombre<strong style={{color:'#832347'}}>*</strong> </Label>
                  <Input value={formDenuncia.Denunciante}
                    name="Denunciante"
                    onChange={updateField} />
                </div>
                <div className='col-sm-6 col-12 p-2'>
                  <Label>Lugar de residencia <strong style={{color:'#832347'}}>*</strong></Label>
                  <Input value={formDenuncia.LugarDeReincidencia}
                    name="LugarDeReincidencia"
                    onChange={updateField} />
                </div>
              </div>
              <div className='d-flex flex-wrap'>
                 <div className='col-sm-8 col-12 p-2'>
              <Label>Lugar de nacimiento*</Label>
              <Input value={formDenuncia.LugarDeNacimiento}
                name="LugarDeNacimiento"
                onChange={updateField} />
            </div> 
                <div className='col-sm-4 col-12 p-2 '>
                  <div>
                    <label>Género<strong style={{color:'#832347'}}>*</strong></label>
                  </div>
                  <Input
                    className='ms-3'
                    name="radio1"
                    type="radio"
                    value="Masculino"
                    onChange={handleSexoChange}
                  />
                  {' '}
                  <Label check>
                    Masculino
                  </Label>
                  <Input
                    className='ms-3'
                    name="radio1"
                    type="radio"
                    value="Femenino"
                    onChange={handleSexoChange}
                  />
                  {' '}
                  <Label check>
                    Femenino
                  </Label>
                  <Input
                    className='ms-3'
                    name="radio1"
                    type="radio"
                    value="Otro"
                    onChange={handleSexoChange}
                  />
                  {' '}
                  <Label check>
                    Otro
                  </Label>
                </div>
                <div className='col-sm-2 col-12 p-2'>
                  <Label>Edad</Label>
                  <Input type="number" value={formDenuncia.Edad}
                    name="Edad"
                    min={1} 
                    max={99}
                    onChange={updateField} />
                </div>
                <div className='col-sm-6 col-12 p-2 '>
                  <div>
                    <label>Sector laboral al que pertenece<strong style={{color:'#832347'}}>*</strong></label>
                  </div>
                  <div className='d-flex flex-wrap'>
                    <div className='d-flex'>
                      <Input
                        className='ms-3'
                        name="radio2"
                        type="radio"
                        value="Publico"
                        onChange={handleSectorLaboralChange}
                      />
                      {' '}
                      <Label className='ms-1' check>
                        Público
                      </Label>
                    </div>
                    <div className='ms-3 d-flex'>
                      <Input
                        className=''
                        name="radio2"
                        type="radio"
                        value="Privado"
                        onChange={handleSectorLaboralChange}
                      />
                      {' '}
                      <Label className='ms-1' check>
                        Privado
                      </Label>
                    </div>
                    <div className='ms-3 d-flex'>
                      <Input
                        style={{ minWidth: '16px' }}
                        name="radio2"
                        type="radio"
                        value="Otro"
                        onChange={handleSectorLaboralChange}
                      />
                      {' '}
                      <Label className='ms-1' check>
                        Otro
                      </Label>
                      <Input
                        className='ms-2'
                        name="sectorLaboral"
                        type="text"
                        value={sectorLaboral} 
                        onChange={(e) => setSectorLaboral(e.target.value)} 
                        disabled={!otroSeleccionado}
                      />

                    </div>
                  </div>
                </div>

              </div>
              <div className='d-flex flex-wrap'>
                <div className='col-sm-3 col-12 p-2'>
                  <Label>Teléfono de contacto</Label>
                  <Input value={formDenuncia.Telefono}
                  type='number'
                    name="Telefono"
                    onChange={updateField} />
                </div>
                <div className='col-sm-6 col-12 p-2'>
                  <Label>Correo electrónico<strong style={{color:'#832347'}}>*</strong></Label>
                  <Input
                    type="email"
                    value={formDenuncia.Email}
                    name="Email"
                    onChange={updateField} />
                </div>
                <div className='col-sm-3 col-12 p-2 '>
                  <div>
                    <label>Ámbito<strong style={{color:'#832347'}}>*</strong></label>
                  </div>
                  <Input
                    className='ms-3'
                    name="radio1"
                    type="radio"
                    value="Estatal"
                    onChange={handleAmbitoChange}
                  />
                  {' '}
                  <Label check>
                    Estatal
                  </Label>
                  <Input
                    className='ms-3'
                    name="radio1"
                    type="radio"
                    value="Municipal"
                    onChange={handleAmbitoChange}
                  />
                  {' '}
                  <Label check>
                    Municipal
                  </Label>
                </div>
              </div>
              <div className='d-flex flex-wrap'>

                <div className='col-sm-6 col-12 p-2'>
                  <Label>Entidad pública<strong style={{color:'#832347'}}>*</strong></Label>
                  <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            onChange={handleSelected}
                            placeholder="Buscar Ente..."
                            loadingMessage={({ inputValue }) => !inputValue ? "Escriba para comenzar a buscar": "Cargando..."}
                            noOptionsMessage = {({ inputValue }) => !inputValue ? "Escriba para comenzar a buscar" : "Sin coincidencias"}
                        />
                </div>
                <div className='col-sm-6 col-12 p-2'>
                  <Label>Área de interés de la propuesta<strong style={{color:'#832347'}}>*</strong></Label>
                  <Input
                    type="select"
                    value={formDenuncia.AreaDeInteres}
                    name="AreaDeInteres"
                    onChange={updateField}>
                    <option value="">Seleccione...</option>
                    <option value={"Accesibilidad"}>Accesibilidad</option>
                    <option value={"Anticorrupción"}>Anticorrupción</option>
                    <option value={"Cultura"}>Cultura</option>
                    <option value={"Obra Pública"}>Obra pública</option>
                    <option value={"Educación"}>Educación</option>
                    <option value={"Gasto Social"}>Gasto social</option>
                    <option value={"Medio Ambiente"}>Medio ambiente</option>
                    <option value={"Presupuesto"}>Presupuesto</option>
                    <option value={"Salud Pública"}>Salud pública</option>
                    <option value={"Servicios Públicos"}>Servicios públicos</option>
                    <option value={"Transparencia"}>Transparencia</option>
                  </Input>
                </div>
              </div>

              <div className='col-12 p-2'>
                <Label>
                  Desarrollo del tema propuesto y los argumentos por los cuales el tema se considera de transcendencia estatal o municipal<strong style={{color:'#832347'}}>*</strong>
                </Label>
                <Input type="textarea" rows="10" value={formDenuncia.Motivo}
                  name="Motivo"
                  onChange={updateField} ></Input>
              </div>
              <div className='d-flex flex-wrap'>
                <div className='col-sm-6 col-12 p-2'>
                  <span className="Input-control text">
                    <Label>Agregar archivo </Label><br />
                    <Input
                      id="EvidenciaPC"
                      name="Evidencia"
                      type="file"
                      ref={fileInput}
                      onChange={updateField}
                      onLoad={() => alert('done')}
                    />
                    <Label htmlFor="EvidenciaPC" className="custom-file-upload">
                    </Label>
                    <span className="file-name"> {fileNames}</span>
                    <br />
                  </span>
                </div>
              </div>
              <div className='p-2'>
                <Label check className="text-bold mr-5">
                  ¿Te gustaría formar parte del Comité de Planeación Estratégica y Participativa de Auditorías?
                </Label>
                <Input type="checkbox"
                  onChange={updateField}
                  checked={formDenuncia.ParteDelComite}
                  name="ParteDelComite"
                  value={formDenuncia.ParteDelComite}
                  style={{ marginLeft: 10 }} />{' '}
                <span style={{ fontSize: "15px" }}>(Marque en caso de aceptar)</span>
              </div>
              <div>
                <p style={{ fontSize: '14px' }}>El Instituto es responsable del tratamiento de los datos personales que le sean proporcionados, los cuales serán protegidos de acuerdo con lo dispuesto en la Ley de
                  Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de
                  Sonora.</p>
              </div>
              <p > <strong style={{color:'#832347'}}><strong style={{color:'#832347'}}>*</strong> Campos obligatorios</strong></p>
              <Button id="SubmitButton" className='btn-default ' > Enviar </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </>
  )
}
