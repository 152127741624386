import React, { Component, useState } from "react";
import axios from "axios";
import { hostSigas, smallScreen, host } from "../../Utils";
import TipoInformes from "../../components/informes/TipoInformes";
import Ejercicios from "../../components/informes/Ejercicios";
import Ambitos from "../../components/informes/Ambitos";
import TipoEntes from "../../components/informes/TipoEnte";
import Entes from "../../components/informes/Entes";
import FondosFederales from "../../components/informes/FondosFederales";
import Informes from "../../components/informes/Informes";
import { Link } from "react-router-dom";
import DownloadBtn from "../../images/buttons/recorte.png";
import InformesCiudadanos from "../../images/cuenta-publica/InformesCiudadanos.png";
import CuentasAnteriores from "../../images/cuenta-publica/CuentasAnteriores.png";
import InformesIndividuales from "../../images/cuenta-publica/InformesIndividuales.png";
import InformesResultados from "../../images/cuenta-publica/InformesResultados.png";
import CuentaBg from "../../images/cuenta-publica/Cuenta-bg.png";


class AuditoriasCP2324 extends Component {
    constructor(props) {
        props.setTitle("   ");
        super();
        var state = {
            hideTipoInforme: false,
            ejercicio: {},
            tipoEntes: [],
            entes: [],
            fondosFederales: [],
            enteName: "",
            informes: [],
            message: "",
            selectedTipoEnte: "",
            tipoInformesLabel: "",
            tipoInformesId: "",
            select_cp_actual: false,
        };

        if (props.tipoInforme !== "Cuenta") {
            axios
                .get(hostSigas() + `/api/TipoInformesNombre/${props.tipoInforme}`)
                .then((x) => {
                    //console.log(x.data);
                    this.setState({
                        hideTipoInforme: true,
                        tipoInformesLabel: x.data.nombre,
                        tipoInformesId: x.data.id,
                    });
                })
                .catch((x) => console.log(x));
        }
        this.state = state;
    }
    execute(data) {
        this.setState(data);
    }


    unselect() {
        this.setState({
            entes: this.state.entes.map((x) => {
                x.selected = false;
                return x;
            }),
        });
    }
    Download(ambito) {
        if (ambito == "estatal") {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6090')
            else
                return window.open(host() + "/Publico/Documento/6093")
        } else {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6091')
            else
                return window.open(host() + "/Publico/Documento/6094");
        }


    }
    DownloadPropuesta(ambito) {
        if (ambito == "estatal") {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6090')
            else
                return window.open(host() + "/Publico/Documento/6122")
        } else {
            if (host() == "https://localhost:44365")
                return window.open(host() + '/Publico/Documento/6091')
            else
                return window.open(host() + "/Publico/Documento/6121");
        }
    }
    DownloadIR() {

        if (host() == "https://localhost:44365")
            return window.open(host() + '/Publico/Documento/6090')
        else
            return window.open(host() + "/Publico/Documento/6123")

    }
    DownloadActualizacion() {

        if (host() == "https://localhost:44365")
            return window.open(host() + '/Publico/Documento/6090')
        else
            return window.open(host() + "/Publico/Documento/6140")

    }

    ChangeAnoRendered2021() {
        var btn2021 = document.getElementById("2021-button");
        var btn2022 = document.getElementById("2022-button");
        var btn2023 = document.getElementById("2023-cp");

        var infres = document.getElementById("btn-inf-res");
        var infciu = document.getElementById("btn-inf-ciu");
        var conjInformes = document.querySelector("#conjunto-informes");
        conjInformes.classList.add("d-none");

        infres.classList.remove("images-deactivate");
        infciu.classList.remove("images-deactivate");

        btn2021.classList.add("backc-color-isaf");
        btn2022.classList.remove("backc-color-isaf");
        btn2023.classList.remove("backc-color-isaf");

        var infactcp = document.getElementById("informes-act-cp");
        infactcp.classList.add("d-none")

        var infindant22 = document.getElementById("inf-ind-ant-2022");
        infindant22.classList.add("d-none")

        var infindant = document.getElementById("inf-ind-ant");
        infindant.classList.remove("d-none")
        var aInformeRes = document.getElementById("informe-ciudadano");
        aInformeRes.href = "/auditorias/informes-ciudadanos";
    }

    ChangeAnoRendered2022() {
        /*var btn2021 = document.getElementById("2021-button");*/
        var btn2022 = document.getElementById("2022-button");
        var btn2023 = document.getElementById("2023-cp");

        var infres = document.getElementById("btn-inf-res");
        var infciu = document.getElementById("btn-inf-ciu");

        var conjInformes = document.querySelector("#conjunto-informes");
        conjInformes.classList.remove("d-none");

        infres.classList.remove("images-deactivate");
        infciu.classList.remove("images-deactivate");

        /*btn2021.classList.remove("backc-color-isaf");*/
        btn2022.classList.add("backc-color-isaf");
        btn2023.classList.remove("backc-color-isaf");

        var infactcp = document.getElementById("informes-act-cp");
        infactcp.classList.add("d-none")

        var infindant22 = document.getElementById("inf-ind-ant-2022");
        infindant22.classList.remove("d-none")

        var infindant = document.getElementById("inf-ind-ant");
        infindant.classList.add("d-none")
        var aInformeRes = document.getElementById("informe-ciudadano");
        aInformeRes.href = "/auditorias/informes-ciudadanos";
    }

    ChangeAnoRendered2023() {
       /* var btn2021 = document.getElementById("2021-button");*/
        var btn2022 = document.getElementById("2022-button");
        var btn2023 = document.getElementById("2023-cp");

        var conjInformes = document.querySelector("#conjunto-informes");
        conjInformes.classList.add("d-none");

        var infres = document.getElementById("btn-inf-res");
        var infciu = document.getElementById("btn-inf-ciu");

        var infactcp = document.getElementById("informes-act-cp");
        infactcp.classList.remove("d-none")

        var infindant = document.getElementById("inf-ind-ant");
        infindant.classList.add("d-none")

        var infindant22 = document.getElementById("inf-ind-ant-2022");
        infindant22.classList.add("d-none")

        infres.classList.add("images-deactivate");
        //infciu.classList.add("images-deactivate");

       /* btn2021.classList.remove("backc-color-isaf");*/
        btn2022.classList.remove("backc-color-isaf");
        btn2023.classList.add("backc-color-isaf");
    }

    render() {

        return (
            <div className="container-fluid cuenta-publica-main">
                <div className="flex-container" style={{ justifyContent: 'flex-start' }}>
                    <div className="flex-container" style={{ width: "400px", display: "flex", justifyContent: "space-evenly" }}>
                      
                        <div className="ano-ctapub-back" id="2022-button" style={{ fontSize: smallScreen() ? '20px' : '28px' }} onClick={() => this.ChangeAnoRendered2022()}>2022</div>
                    </div>
                    <div id="2023-cp" onClick={() => this.ChangeAnoRendered2023()} className="current-cp-title backc-color-isaf" style={{ fontSize: smallScreen() ? '24px' : '42px' }}>CUENTA PÚBLICA 2023</div>
                    <span>Con base en el artículo 22 de la Ley de Fiscalización Superior para el Estado de Sonora, los Informes de Resultados tendrán el carácter de información pública, una vez que sean sancionados por el H. Congreso del Estado de Sonora, a más tardar el 15 de octubre de 2023.</span>
                    <br />
                </div>
                <div className="flex-container cuenta-buttons" style={{ justifyContent: 'space-evenly' }}>
                    <a id="inf-ind-ant" className="d-none" href={`/auditorias/informes-individuales?ejercicio=${2021}`}><img src={InformesIndividuales} style={smallScreen() ? { width: "150px" } : {}} /></a>
                    <a id="inf-ind-ant-2022" className="d-none" href={`/auditorias/informes-individuales?ejercicio=${2022}`}><img src={InformesIndividuales} style={smallScreen() ? { width: "150px" } : {}} /></a>
                    <a id="informes-act-cp" href={"/informes/informes-cuenta-actual"}><img src={InformesIndividuales} style={smallScreen() ? { width: "150px" } : {}} /></a>

                    <a href="/auditorias/informes-de-resultados"><img id="btn-inf-res" className="images-deactivate" src={InformesResultados} style={smallScreen() ? { width: "150px" } : {}} /></a>

                    <a id="informe-ciudadano"  href="/auditorias/informes-ciudadanos-actual"><img id="btn-inf-ciu"  src={InformesCiudadanos} style={smallScreen() ? { width: "150px" } : {}} /></a>
                    <a href={"/auditorias/cuentas-publicas-anteriores"}><img src={CuentasAnteriores} style={smallScreen() ? { width: "150px" } : {}} /></a>
                </div>
                <div className="flex-container flex-center d-none" id="conjunto-informes">
                    <div style={{ width: "590px", display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                        <div className="flex-container download-button" onClick={() => this.DownloadIR()}>
                            <span className="download-icon-container">
                                <span className="download-icon-inside"><i className="download-icon bi bi-download"></i></span>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "15px", fontWeight: "600" }}>Informe de Resultados del Gobierno del Estado</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.Download('estatal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "15px", fontWeight: "600" }}>Informe Ciudadano del Gobierno del Estado</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.DownloadPropuesta('estatal')}>
                            <span className="download-icon-container">
                                <span className="download-icon-inside"><i className="download-icon bi bi-download"></i></span>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Propuesta de calificación del Gobierno del Estado</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.Download('municipal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Informe Ciudadano de Municipios</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.DownloadPropuesta('municipal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Propuesta de calificación de Municipios</span>
                        </div>
                        <div className="flex-container download-button" onClick={() => this.DownloadActualizacion('municipal')}>
                            <span >
                                <img src={DownloadBtn} width={50}></img>
                            </span>
                            <span style={{ marginLeft: "6px", color: "#fff", fontSize: "14px", fontWeight: "600" }}>Actualización de la Propuesta de calificación de Municipios</span>
                        </div>

                    </div>
                </div>



            </div>
        );
    }
}

export default AuditoriasCP2324;
