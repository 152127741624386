import React from 'react';
import {  NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from '../images/logo-300.png';
import LogoSigas from '../images/logo-sigas-200-stroke.png';
//import infografias from '../images/iconos/social/infografias.png';
//import audio from '../images/iconos/social/audio.png';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import './NavMenu.css';
import { deviceDetect } from 'react-device-detect';

export function NavMenu(props) {

    const isMobile =()=>{
        return window.innerWidth < 480;
    }
    const Institucion = () => {
        return (
            <div>
                <MenuItem href="/institucion/acerca-de">Acerca de</MenuItem>
                <MenuItem href="/institucion/directorio">Directorio</MenuItem>
                <MenuItem href="/institucion/marco-normativo">Marco Normativo</MenuItem>
                <MenuItem href="/institucion/documentos-de-interes">Documentos de Interes</MenuItem>
                <MenuItem href="/institucion/programa-anual">Programa Anual de Auditorías</MenuItem>
                <MenuItem href="/institucion/acuerdos">Substanciación</MenuItem>
                <MenuItem href="/institucion/planeacion-institucional">Planeación institucional</MenuItem>
                <MenuItem href="/institucion/organo-interno-de-control">Órgano Interno de Control</MenuItem>
                <MenuItem href="/institucion/denuncias">Denuncias</MenuItem>
                {/* <MenuItem href="/institucion/preguntas-frecuentes">Preguntas frecuentes</MenuItem>
                <MenuItem href="/institucion/estructura-organica">Estructura orgánica</MenuItem>
                <MenuItem href="/institucion/capacitaciones">Reservorio de capacitaciones</MenuItem>
                <MenuItem href="/transparencia/obligaciones-de-transparencia/70/VII">Directorio</MenuItem>
                <MenuItem href="https://outlook.office.com/">Correo institucional</MenuItem>
            <MenuItem href="/institucion/manuales-y-guias">Manuales y guías</MenuItem> */}
                {/* <MenuItem href="/institucion/normatividad-interna">Normatividad institucional</MenuItem>
                <MenuItem href="/institucion/compendio-legislativo">Compendio legislativo</MenuItem>                 */}
                {/* <MenuItem href="/institucion/leyes-de-ingresos">Leyes de ingreso</MenuItem>
                <MenuItem href="/institucion/notas">Notas informativas</MenuItem>
            <MenuItem href="/institucion/codigo-de-etica">Código de ética</MenuItem> */}
            </div>
            )
        }
        const Auditorias = () => {
        return (
            <div>
                {//<MenuItem href="/auditorias/resumen-ciudadano">Resumen Ciudadano</MenuItem>
                }
                {/*<MenuItem href="/auditorias/informes-individuales">Informes Individuales</MenuItem>
                <MenuItem href="/auditorias/informes-de-resultados">Informes de Resultados</MenuItem>
            <MenuItem href="/auditorias/resumen-ciudadano">Resumen Ciudadano</MenuItem>*/}
                {/*<MenuItem href="/auditorias/informes-cuenta-actual">Informes Cuenta en Revisión</MenuItem>*/}
                <MenuItem href="/auditorias/avance-solventacion">Avance de solventación</MenuItem>                
                <MenuItem href="/auditorias/auditorias-covid">Auditorías COVID</MenuItem>
                <MenuItem href="https://sigas.isaf.gob.mx">Solventación electrónica &nbsp;<img src={LogoSigas} width={'100px'}></img></MenuItem>
            </div>
           
        )
    }
    const Evaluaciones = () => {
        return (
            <div>
            </div>
        )
    }
    const Transparencia = () => {
        return (
            <div>
                <MenuItem href="/transparencia/solicitud-de-informacion">Solicitud de información</MenuItem>
                <MenuItem href="/transparencia/obligaciones-de-transparencia">Obligaciones de transparencia</MenuItem>
                <MenuItem href="/transparencia/procedimientos-de-acceso">Procedimiento de acceso a la información</MenuItem>
            <MenuItem href="/transparencia/participacion-ciudadana">Participación ciudadana</MenuItem>
                <MenuItem href="/transparencia/aviso-privacidad">Aviso de privacidad</MenuItem>
                
                {/* <MenuItem href="/transparencia/informe-mensual-de-transparencia">Informe mensual de transparencia</MenuItem>
                <MenuItem href="/transparencia/normatividad-transparencia">Normatividad en transparencia</MenuItem>
                <MenuItem href="/transparencia/lgcc-y-ley-de-austeridad">Transparencia de la LGCC y de la Ley de Austeridad y Ahorro</MenuItem>
                <MenuItem href="/transparencia/sevac">SEVAC</MenuItem>
                <MenuItem href="/transparencia/unidad-de-transparencia">Unidad de Transparencia</MenuItem>
            <MenuItem href="/transparencia/convenios-municipales">Convenios municipales</MenuItem> */}
            </div>
        )
    }
    
    const Difusion = () => {
        return (
            <div>
                <MenuItem href="/difusion/revista-valor-publico">Revista Valor Público</MenuItem>
                <MenuItem href="/difusion/tableros-interactivos">Tableros Interactivos</MenuItem>
                <MenuItem href="/difusion/notas">Notas Informativas</MenuItem> 
                <MenuItem href="/difusion/analisis-de-coyuntura">Análisis de Coyuntura</MenuItem>
                <MenuItem href="/difusion/foros-y-eventos">Foros y eventos</MenuItem>
               
            </div>
        )
    }
    const Informes = () =>{
        return(
            <div>
                <MenuItem href="/informes/cuenta-publica-2324">Cuenta Pública</MenuItem>
                {/*<MenuItem href="/informes/cuenta-publica">Cuenta Pública</MenuItem>*/}
                <MenuItem href="/informes/informe-indicadores">Informe Anual de Indicadores</MenuItem>
                <MenuItem href="/informes/informes-tematicos">Informes temáticos</MenuItem>
                <MenuItem href="/informes/politicas-publicas">Políticas públicas</MenuItem>
                <MenuItem href="/informes/informe-actividades">Informe de actividades</MenuItem>
               
            </div>
        )
    }
    
    
    return (
        <div className="menu-header">          

            <NavbarBrand tag={Link} to="/" > 
                <img className='logo-Navbar' src={Logo} alt="Logo" />
            </NavbarBrand>

            <div className="menu-container" style={{} }>
              <Menu 
              menuButton={<MenuButton 
              className={'btn btn-default btn-lg'}>
                INSTITUCIÓN
                </MenuButton>}  
                align='center' 
                arrow={true}  
                transition>
                  {Institucion()}
                </Menu>|
                <Menu
                    menuButton={<MenuButton
                        className={'btn btn-default btn-lg'}>
                        INFORMES</MenuButton>}
                    align='center'
                    arrow={true}
                    transition>
                    {Informes()}
                </Menu>|
              {/* <Menu 
              menuButton={<MenuButton 
              className={'btn btn-default btn-lg'}>
                AUDITORÍAS
                </MenuButton>} 
                align='center' 
                arrow={true}  
                transition>
              {Auditorias()}
                </Menu>| */}
              <Menu 
              menuButton={<MenuButton 
                className={'btn btn-default btn-lg'}>
                    TRANSPARENCIA</MenuButton>} 
              align='center' 
              arrow={true} 
              transition>
                  {Transparencia()}
                </Menu>|
                <Menu
                    menuButton={<MenuButton
                        className={'btn btn-default btn-lg'}>
                        DIFUSIÓN</MenuButton>}
                    align='center'
                    arrow={true}
                    transition>
                    {Difusion()}
                </Menu>
            </div>
            <div className='sections'>
              {/*
                <span>
                  <span>
              <img width={30} src={audio} /> AUDIO
              </span>
              <span>
                <img width={30} src={infografias} /> INFOGRAFÍAS
              </span>
    </span>*/}

            </div>
        </div>
     
  );
}
