import React, { useEffect, useState } from 'react';
import {
    hostSigas, useConstructor, smallScreen
} from '../../Utils';
import { Card, Col, Collapse, CardBody } from 'reactstrap';
import { useParams } from 'react-router-dom';
import '../InformesIndividuales.css';
import axios from 'axios';


import ImgAlcance from '../../images/informe-ciudadano/Alcance.png';
import ImgObservaciones from '../../images/informe-ciudadano/observaciones.png';
import ImgRecomendaciones from '../../images/informe-ciudadano/recomendaciones.png';
import ImgQueEncontramos from '../../images/informe-ciudadano/Queencontramos.png';
import { Loading } from '../../components/Loading';


export function InformesCuentaActualDetalle(props) {
    const { auditoriaId } = useParams();
    const [Auditoria, setAuditoria] = useState([]);
    const [Ciudadano, setCiudadano] = useState([]);
    const [ObsCiudadano, setObsCiudadano] = useState([]);
    const [Ejecutivo, setEjecutivo] = useState([]);
    const [Egresos, setEgresos] = useState([]);
    const [Ingresos, setIngresos] = useState([]);
    const [ObsEgresos, setObsEgresos] = useState([]);
    const [ObsIngresos, setObsIngresos] = useState([]);
    const [ObsOtrosRubros, setObsOtrosRubros] = useState([]);
    const [VerCiudadano, setVerCiudadano] = useState([]);
    const [loading, setLoading] = useState(false);
    //const [paramAuditoriaId, setParamAuditoriaId] = useState([]);
    const [innerSize, setInnerSize] = useState(0);
    const [collapse, setCollapse] = useState(false);

    useConstructor(() =>{
        props.setTitle("Ejercicio 2023");
        console.log("Parametros ", auditoriaId);  
        setVerCiudadano(true)
        
    });

    useEffect(() => {
        let widthSize = window.innerWidth;
        console.log(widthSize);
        setInnerSize(widthSize);
        CargaDatos()
       //updateInformes();
       
    }, [auditoriaId]);


    const CargaDatos= async ()=>{
        setLoading(true)
       await DatosAuditoria();
       await DatosInformeCiudadano();
       await DatosInformeEjecutivo();
        setLoading(false)
    }
    
    const DatosAuditoria= async() => {
        axios.get(
            hostSigas() + "/api/Informes/website/auditoria/" + auditoriaId,
            
        ).then(res => {
            setAuditoria(res.data)
        })

    }
    const DatosInformeCiudadano= async()=> {
       const res=  await axios.get(
            hostSigas() + "/api/Informes/website/ciudadano/auditoria/" + auditoriaId,
        )
        setCiudadano(res.data);
        setObsCiudadano(res.data.encontramos);
        console.log("Datos ciudadano ", res.data);
        if((!res.data.encontramos)||(res.data.encontramos && res.data.encontramos.length <= 0)){
            //console.log('CAMBIO VER CIUDANO')
            setVerCiudadano(false)
        }
    }
    const DatosInformeEjecutivo = async()=> {
       const res= await axios.get(
            hostSigas() + "/api/Informes/website/ejecutivo/auditoria/" + auditoriaId,
        )
        console.log("Datos Ejecutivo ", res.data);
        setEjecutivo(res.data);
        if (res.data.isDependencia == false) {
            setIngresos(res.data.ingresos);
            setObsIngresos(res.data.descripcionesEjecutivasIngreso);
        }
        setEgresos(res.data.gasto);
        setObsEgresos(res.data.descripcionesEjecutivasEgreso);
        //console.log(Ejecutivo);
        setObsOtrosRubros(res.data.descripcionesEjecutivasOtrosRubros);

    }

    function fetchFile(name, downloadName) {
        console.log(name,downloadName)
        let ext = name.split('.').pop();
        var link = document.createElement('a');
        name = name.replace('/', '-');
        downloadName = downloadName.replace('/', '-');
        //link.href = `${hostSigas()}/api/Storage/GetUrlWord/` + name + '/' + downloadName + '.' + ext;
        window.open(`${hostSigas()}/api/Storage/GetUrl/` + name + '/' + downloadName + '.' + ext, '_blank');
        link.click();
    }
    //const correctList = Ciudadano.encontramos.map(name => <li key={name}>{name}</li>);
    function InformeCiudadano() {
        return <div className="flex-column">
            <div className="font-title-ciudadano">INFORME CIUDADANO</div> 

            <div className="flex-container evenly" style={{ alignItems: 'flex-start' }}>
                <div className="flex-column" style={{ alignItems: 'flex-start' }}>
                    <div className="flex-column">
                        <img src={ImgAlcance} width="450px !important" alt="Alcance" />
                        <div className="flex-container" style={{ display: Ejecutivo.isDependencia ? 'none' : '' }}>
                            <span className="text-label">Ingreso:</span>
                            <Card className={'alcance-card'} >{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ciudadano.ingreso)}</Card>
                        </div>
                        <div className="flex-container" style={{ display: Ejecutivo.isDependencia ? 'none' : '' }}>
                            <span className="text-label">Muestra:</span><Card className={'alcance-card'} >{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ciudadano.ingresoMuestra)}</Card>
                        </div>
                        <div className="flex-container" style={{ display: Ejecutivo.isDependencia ? 'none' : '' }}>
                            <span className="text-label">Alcance:</span><Card className={'alcance-card'} >{Ciudadano.ingresoAlcance}%</Card>
                        </div>
                            
                        <div className="flex-container">
                            <span className="text-label">Egreso:</span><Card className={'alcance-card'} >{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ciudadano.egreso)}</Card>
                        </div>
                        <div className="flex-container">
                            <span className="text-label">Muestra:</span><Card className={'alcance-card'} >{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ciudadano.egresoMuestra)}</Card>
                        </div>
                        <div className="flex-container">
                            <span className="text-label">Alcance:</span><Card className={'alcance-card'} >{Ciudadano.egresoAlcance}%</Card>
                        </div>
                    </div>
                </div>
                <div className="flex-column">
                    <div className="flex-column">
                        <img src={ImgObservaciones} width="450px !important" />
                        <div className="flex-container center">
                            <div className="flex-column">
                                <span className="text-num">{Ciudadano.observaciones}</span>
                                <span className="text-obs">DETERMINADAS</span>
                            </div>
                            <span className="linea-vertical"></span>
                            <div className="flex-column">
                                <span className="text-num">{Ciudadano.observacionesPorSolventar}</span>
                                <span className="text-obs">SOLVENTADAS</span>
                            </div>

                        </div>

                    </div>

                    <div className="flex-column"><img src={ImgRecomendaciones} width="450px !important" alt="Recomendaciones" />
                        <div className="flex-container center">
                            <div className="flex-column">
                                <span className="text-num">{Ciudadano.recomendaciones}</span>
                                <span className="text-obs">DETERMINADAS</span>
                            </div>
                            <span className="linea-vertical"></span>
                            <div className="flex-column">
                                <span className="text-num">{Ciudadano.recomendacionesPorSolventar}</span>
                                <span className="text-obs">IMPLEMENTADAS</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-column">
                <img src={ImgQueEncontramos} width="450px !important" alt="Alcance" />
                <div className="lista-obs">
                {ObsCiudadano && ObsCiudadano.length > 0 && (
                        <ul style={{ listStyle: 'square', color: '#CCB656' }}>
                            {ObsCiudadano.map(item =>
                                <li key={item}>
                                    <span style={{ color: '#706F6F' }}>{item}</span>
                                </li>
                            )}
                        </ul>
                    )}
                </div>

            </div>
            <div className='d-flex mt-3' style={{ justifyContent: 'center' }}>
                <p style={{ fontSize: '0.8rem' }}>
                    {ObsCiudadano.length > 0 === true ?
                        <span>* Para este informe ciudadano solo se describen las observaciones relevantes no solventadas, </span> :
                        <span>* Para este informe ciudadano no se determinaron observaciones relevantes de que hacer mención,</span>
                    } correspondientes al informe individual notificado con fecha {Ejecutivo.fechaNotificacion}
                </p>
            </div>
        </div>;
        

   }
   
    function InformeEjecutivo() {
        return  <div className="flex-column" >
            <div className="font-title-ejecutivo">RESUMEN EJECUTIVO</div>
           
            <div className="flex-column" style={{ alignItems: 'flex-start' }}>

                <div className="flex-column" style = {{ display: Ejecutivo.isDependencia?'none':'block'} }>
                    <div className="flex-container evenly" style={{ alignItems: '' }}>
                        <span className="font-subtitle-ejecutivo">INGRESOS</span><div className="hr-subtitle-ejecutivo"></div>
                    </div>
                    <div className="flex-column lista-obs">
                        <p>{Ejecutivo.textoIngreso}</p>
                        {(Ejecutivo.isMunicipio || Ejecutivo.isParamunicipal)? <table className="table table-sm table-bordered" style={{ fontSize: 'small' }}>
                            <thead >
                                <tr>
                                    <th width="60%">Rubro</th>
                                    <th align="center">Ingresos Devengado</th>
                                    <th align="center">Total Revisado</th>
                                    <th align="center">% Alcance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Ingresos.map(item =>
                                    <tr id={item.id}>
                                        <td>{item.concepto + " " + item.objeto}</td>
                                        <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.devengado)}</td>
                                        <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.revisado)}</td>
                                        <td style={{textAlign:'center'}}>{item.porcentaje}%</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr >
                                    <td>Total</td>
                                    <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ejecutivo.totalDevengadoIngreso)}</td>
                                    <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ejecutivo.totalRevisadoIngreso)}</td>
                                    <td style={{textAlign:'center'}}>{Math.round(Ejecutivo.totalAlcanceIngreso)}%</td>
                                </tr>
                            </tfoot>
                        </table>:"" }
                    </div>
                    <div className="flex-container">
                        <span className="font-subtitle-ejecutivo-res">RESULTADOS DE INGRESOS:</span>
                    </div>
                    <div className="lista-obs">
                        <ul style={{ listStyle: 'square', color: '#CCB656' }}>
                            {Ejecutivo.tieneObsIngresos ? ObsIngresos.map(item =>
                                <li key={item}>
                                    <span style={{ color: '#706F6F' }}>{item}</span>
                                </li>
                            ) : <span style={{ color: '#706F6F' }}>De la revisión al presente rubro se obtuvieron resultados satisfactorios.</span>}
                        </ul>
                    </div>

                </div>
                <div className="flex-column">
                    <div className="flex-container evenly" style={{ alignItems: 'center' }}>
                        <span className="font-subtitle-ejecutivo">EGRESOS</span><div className="hr-subtitle-ejecutivo"></div>
                    </div>
                    <div className="flex-column lista-obs">
                        <p>El Sujeto Fiscalizado manifestó en el Informe de {Auditoria.periodoAuditado} del ejercicio 2023, un total de egresos devengados por {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN", maximumFractionDigits: 0, }).format(Ejecutivo.totalDevengado)}, por lo que este Órgano Superior de Fiscalización revisó una muestra de {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN", maximumFractionDigits: 0 }).format(Ejecutivo.totalRevisado)} que representa el {Math.round(Ejecutivo.totalAlcance)}% del total del recurso en comento, integrándose como sigue:</p>
                        {(!Ejecutivo.IsAC && !Ejecutivo.isSindicato)                     ?
                            <table className="table table-sm table-bordered" style={{ fontSize: 'small' }}>
                                {/* <caption>{Ejecutivo.TieneEnfoqueObra ? "Del total de egresos devengados en el capítulo 6000 Inversión Pública por " + Ejecutivo.TotalDevengadoObra+", la revisión del enfoque de la Auditoría Técnica a la Obra Pública fue de "+Ejecutivo.TotalRevisadoObra+", que representa el "+Ejecutivo.TotalAlcanceObra+"%.":""}</caption>*/}
                                <thead >
                                    <tr>
                                        <th width="60%">Capitulo del Gasto</th>
                                        <th align="center">Total Devengado</th>
                                        <th align="center">Total Revisado</th>
                                        <th align="center">% Alcance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Egresos.map(item =>
                                        <tr id={item.id}>
                                            <td>{item.concepto + " " + item.objeto}</td>
                                            <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.devengado)}</td>
                                            <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.gastoAuditoria)}</td>
                                            <td style={{textAlign:'center'}}>{item.alcanceAuditoria}%</td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ejecutivo.totalDevengado)}</td>
                                        <td style={{textAlign:'end'}}>{new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(Ejecutivo.totalRevisado)}</td>
                                        <td style={{textAlign:'center'}}>{Math.round(Ejecutivo.totalAlcance)}%</td>
                                    </tr>
                                </tfoot>
                            </table> : ""}
                    </div>
                    <div className="flex-container">
                        <span className="font-subtitle-ejecutivo-res">RESULTADOS DE EGRESOS:</span>
                    </div>
                    <div className="lista-obs">
                        <ul style={{ listStyle: 'square', color: '#CCB656' }}>
                            {Ejecutivo.tieneObsEgresos ? ObsEgresos.map(item =>
                                <li key={item}>
                                    <span style={{ color: '#706F6F' }}>{item}</span>
                                </li>
                            ) : <span style={{ color: '#706F6F' }}>De la revisión al presente rubro se obtuvieron resultados satisfactorios.</span>}
                        </ul>
                    </div>
                    <div className="flex-container">
                        <span className="font-subtitle-ejecutivo-res">RESULTADOS DE OTROS RUBROS</span>
                    </div>
                    <div className="lista-obs">
                        <ul style={{ listStyle: 'square', color: '#CCB656' }}>
                            {Ejecutivo.tieneObsOtros ? ObsOtrosRubros.map(item =>
                                <li key={item}>
                                    <span style={{ color: '#706F6F' }}>{item}</span>
                                </li>
                            ) : <span style={{ color: '#706F6F' }}>De la revisión al presente rubro se obtuvieron resultados satisfactorios.</span>}
                        </ul>
                    </div>
                    {Ejecutivo.tieneEnfoqueDesempeño? <>
                    <div className="flex-container">
                        <span className="font-subtitle-ejecutivo-res">RESULTADOS DE DESEMPEÑO</span>
                    </div>
                    <div className="lista-obs">
                        <ul style={{ listStyle: 'square', color: '#CCB656' }}>
                            { Ejecutivo.tieneEnfoqueDesempeño? <span  style={{ color: '#706F6F' }}>{Ejecutivo.textoDesempeño}</span> : <span style={{ color: '#706F6F' }}>De la revisión al presente rubro se obtuvieron resultados satisfactorios.</span>}
                        </ul>
                    </div>
                    </>
                    :<div></div>  
                }
                   

                </div>
                

            </div>

        </div>;
    } 

  
    return (
        <div>
            <div>{props.setTitle(Auditoria.ente)}</div>
            <img alt="" className="carnet-pic" src={hostSigas()+"/Entes/GetEntesLogo/" + Auditoria.escudoEnte} />
            <div className="d-flex" style={{flexDirection:'row', flexWrap:'wrap-reverse'}}>
                
                <div style={{overflowX:'auto'}}>
                <Col id="results" className="filter-content">
                    <Card style={{width:'fit-content'}} body>
                        <div className="flex-container evenly" >
                        <Loading isVisible={loading}></Loading>
                            { VerCiudadano===true ? InformeCiudadano() : InformeEjecutivo()}
                            
                        </div>
                        
                        
                    </Card>
        
                </Col>
                </div>
                <div className='d-flex' style={{flexDirection:'column', alignItems:'start'}}>
                <button className={'btn btn-default filters-button'}
                    onClick={() => setCollapse(!collapse)}
                    style={{ width: '40px', justifyContent:'top'}}
                >
                    {!collapse ? <i class="bi bi-list"></i> : <i class="bi bi-x-circle"></i>}
                </button>

                <Collapse
                    isOpen={collapse}
                    className={smallScreen() ? '' : 'show'}
                    id='filtros'
                    style={{ maxWidth: 350 }}>
                    
                    
                    <div className="filter-form flex-column">
                        <h5 >Auditoria <b>{Auditoria.codigo}</b></h5><div>{Auditoria.periodoAuditado} 2023</div>
                        <hr />
                        <div className="flex-column" style={{ paddingLeft: '15px' }}>
                            
                                <>
                                    <div style={{ cursor: 'pointer' }} ><a onClick={() => setVerCiudadano(true)} ><i className="bi bi-box-arrow-left"></i>  Informe Ciudadano</a></div> <hr />
                                </>
                        
                                <>
                                    <div style={{ cursor: 'pointer' }} ><a onClick={() => setVerCiudadano(false)}><i className="bi bi-box-arrow-left"></i>  Resumen Ejecutivo</a></div>
                                    <hr />
                                </>
                            <div style={{ cursor: 'pointer' }}><a onClick={() => fetchFile(Auditoria.informe, Auditoria.codigo)}><i className="bi bi-box-arrow-down"></i>  Descargar Informe completo</a></div>
                        </div>

                    {/*<h5 className="font-serif">Tipo de Auditoría</h5>*/}

                    </div>
                </Collapse> 
                    </div>

            </div>

      </div>
    );
  
}
